<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { ReviewStatus } from '@/core/constants/enums';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    REVIEWS_GET_REVIEWS,
    REVIEWS_INITIAL_REVIEWS_STATE
  } from '@/core/store/reviews.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ReviewList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.review'),
            route: { name: ROUTE_NAME.REVIEW }
          },
          { title: i18nHelper.getMessage('label.reviewList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.REVIEW,
        actions: {
          read: ACTION.REVIEW_READ,
          update: ACTION.REVIEW_UPDATE,
        }
      },
      filters: [
        {
          label: 'rating',
          key: 'rating',
          value: null,
          options: [
            { text: 1, value: 1},
            { text: 2, value: 2},
            { text: 3, value: 3},
            { text: 4, value: 4},
            { text: 5, value: 5},
          ],
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'status',
          key: 'status',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.reviewStatus),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'reviews',
          state: 'reviews',
          action: REVIEWS_GET_REVIEWS,
          initialStateAction: REVIEWS_INITIAL_REVIEWS_STATE,
          title: i18nHelper.getMessage('label.getReviewList')
        },
        viewDetails: {
          routeName: ROUTE_NAME.REVIEW_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'reviewer',
          name: 'reviewer',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'rating',
          name: 'rating',
          active: true,
          sortable: true,
        },
        {
          key: 'comment',
          name: 'comment',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'status',
          name: 'status',
          active: true,
          sortable: true,
          class: 'text-capitalize',
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL,
          config: {
            enumKey: 'enumReviewStatus',
            statusColor: (value) => {
              let color = {
                [ReviewStatus.PENDING]: 'primary',
                [ReviewStatus.DECLINED]: 'danger',
                [ReviewStatus.APPROVED]: 'success'
              };

              return color[value];
            }
          }
        },
        {
          key: 'createdAt',
          name: 'submittedDateTime',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
    })
  };
</script>

<style></style>
